<template>
  <el-dialog
    title="配对"
    :visible.sync="dialogVisible"
    width="678px"
    @closed="resetForm"
    center
    :close-on-click-modal="false"
  >
    <div class="form_box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="formRef"
        label-width="100px"
      >
        <el-form-item label="设备名称" prop="region">
          <el-select
            v-model="ruleForm.id"
            placeholder="请选择待配置设备"
            class="w100"
            filterable
            remote
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.addr"
              :value="item.id"
            ></el-option>
          </el-select> </el-form-item
      ></el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { GetWaitConfigListApi, PairDeviceApi } from '@/api'

export default {
  data() {
    return {
      rowInfo: '',
      dialogVisible: false,
      ruleForm: {
        id: ''
      },
      rules: {
        id: [{ required: true, message: '请选择待配置设备', trigger: 'change' }]
      },
      // 是否在加载中
      loading: false,
      // 选项
      options: []
    }
  },
  methods: {
    // 打开弹窗
    open(row) {
      this.rowInfo = row
      this.dialogVisible = true
      this.remoteMethod('')
    },

    // 获取数据
    async remoteMethod(kw) {
      const params = {
        id_operator: this.rowInfo.id_operator,
        addr: kw.trim(),
        page_num: 50,
        page_id: 0
      }
      const { ret, data, msg } = await GetWaitConfigListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.options = data.data
    },

    // 重置表单
    resetForm() {
      try {
        this.$refs.formRef.resetFields()
      } catch (e) {}
    },

    // 确定
    confirm() {
      this.$refs.formRef.validate(async _ => {
        if (!_) return
        const params = {
          id_device: this.rowInfo.id_device,
          id: this.ruleForm.id
        }
        const { ret, data, msg } = await PairDeviceApi(params)
        if (ret !== 0) {
          return this.$message.error(msg)
        }
        this.$message.success('配对成功')
        this.dialogVisible = false
        this.$emit('refresh')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form_box {
  padding: 50px 20px 30px;
}
</style>
