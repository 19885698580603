<template>
  <el-dialog
    title="分配运营商"
    :visible.sync="dialogVisible"
    width="600px"
    :closeOnClickModal="false"
    @closed="clearForm"
    center
  >
    <div class="select_box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="formRef"
        label-width="100px"
      >
        <el-form-item label="运营商" prop="id_operator">
          <el-select
            v-model="ruleForm.id_operator"
            filterable
            placeholder="请选择运营商"
            class="w100"
          >
            <el-option
              v-for="item in operatorList"
              :key="item.id_operator"
              :label="item.name_operator"
              :value="item.id_operator"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { DistributionApi } from '@/api'
import { check_operator } from '@/utils/validator.js'

export default {
  data() {
    return {
      // 是否显示
      dialogVisible: false,
      // 设备号
      id: '',
      // 运营商列表
      operatorList: [],
      // 选中的运营商
      ruleForm: {
        id_operator: ''
      },
      rules: {
        id_operator: check_operator
      }
    }
  },
  methods: {
    // 外部调用,显示dialog
    open(id, operatorList) {
      this.id = id
      this.operatorList = operatorList
      this.dialogVisible = true
    },

    // 关闭事件
    clearForm() {
      try {
        this.$refs.formRef.resetFields()
      } catch (e) {}
    },

    // 点击确定
    confirm() {
      this.$refs.formRef.validate(async _ => {
        if (!_) return
        const params = {
          id: this.id,
          id_operator: this.ruleForm.id_operator
        }
        const { ret, data, msg } = await DistributionApi(params)
        if (ret !== 0) {
          return this.$message.error(msg)
        }
        this.$message.success('分配成功')
        this.dialogVisible = false
        this.$emit('refresh')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.select_box {
  padding: 50px 20px 30px;
}
</style>
