<template>
  <div class="imei">
    <div class="card card_container">
      <!-- 标题头 -->
      <div class="title">
        <div class="color"></div>
        <span>IMEI列表</span>
      </div>
      <!-- 选择器 -->
      <div class="selector">
        <el-select
          v-model="id_operator"
          placeholder="全部运营商"
          style="margin-right: 14px"
          @change="id_project = ''"
          v-supAdmin
        >
          <el-option
            v-for="item in operatorList"
            :key="item.id_operator"
            :label="item.name_operator"
            :value="item.id_operator"
          >
          </el-option>
        </el-select>

        <el-select
          v-model="state"
          placeholder="全部状态"
          style="margin-right: 14px"
        >
          <el-option
            v-for="item in [
              { id: 1, name: '在线' },
              { id: 0, name: '离线' }
            ]"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-input
          class="input"
          placeholder="输入IMEI号进行搜索"
          v-model="id_device"
          prefix-icon="el-icon-search"
        ></el-input>

        <el-button type="primary" style="margin: 0 6px 0 20px" @click="search"
          >查询</el-button
        >
        <el-button type="success" @click="reset">重置</el-button>
      </div>

      <!-- 表格 -->
      <div class="table_box">
        <el-table
          :data="IMEIList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb'
          }"
          size="small"
        >
          <el-table-column label="IMEI号" prop="id_device"></el-table-column>
          <el-table-column label="状态">
            <template v-slot="scope">
              <span>
                {{ scope.row.state | stateFmt }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="运营商" v-if="level === 100">
            <template v-slot="scope">
              <span>
                {{ scope.row.name_operator || '-' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="time_create"
          ></el-table-column>

          <el-table-column label="操作">
            <template v-slot="scope">
              <span
                class="edit"
                style="margin-right: 12px"
                v-if="level === 100"
                @click="distribution(scope.row)"
                >分配</span
              >
              <span
                class="edit"
                v-if="scope.row.id_operator"
                @click="pairDevice(scope.row)"
                >配对</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页功能 -->
      <div class="pages">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentPageChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 分配运营商弹窗 -->
    <DistributionOpt ref="distributionRef" @refresh="getDeviceList" />

    <!-- 配对 -->
    <PairDevice ref="pairDeviceRef" @refresh="getDeviceList" />
  </div>
</template>

<script>
import { GetImeiListApi } from '@/api'
import operator from '@/mixins/operator.js'
import DistributionOpt from './cpts/distribution.vue'
import PairDevice from './cpts/pair_device.vue'

export default {
  name: 'imei',
  mixins: [operator],
  components: { DistributionOpt, PairDevice },
  data() {
    return {
      // 权限级别
      level: '',
      // 运营商
      id_operator: '',
      // 状态 1在线 0离线
      state: '',
      // IMEI号
      id_device: '',
      IMEIList: [],
      total: 0,
      page_id: 0,
      page_num: 20
    }
  },
  methods: {
    // 分页尺寸变化
    sizeChange(size) {
      this.page_num = size
      this.getDeviceList()
    },
    // 当前页变化
    currentPageChange(page) {
      this.page_id = page - 1
      this.getDeviceList()
    },

    // 查找
    search() {
      this.page_id = 0
      this.getDeviceList()
    },

    // 重置
    reset() {
      this.page_id = 0
      this.id_operator = ''
      this.state = ''
      this.id_device = ''
      this.getDeviceList()
    },

    // 获取设备列表
    async getDeviceList() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }
      if (this.id_operator) {
        params.id_operator = this.id_operator
      }
      if (this.state !== '') {
        params.state = this.state
      }
      if (this.id_device.trim()) {
        params.id_device = this.id_device.trim()
      }
      const { ret, data, msg } = await GetImeiListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.total = data.cnt_all
      this.IMEIList = data.data
    },

    // 分配
    distribution(row) {
      this.$refs.distributionRef.open(row.id, this.operatorList)
    },

    // 配对设备
    pairDevice(row) {
      this.$refs.pairDeviceRef.open(row)
    }
  },
  created() {
    this.level = this.$store.getters.level
    if (this.level === 100) {
      this.getOperatorList()
    }
    this.getDeviceList()
  },
  filters: {
    stateFmt(v) {
      return v ? '在线' : '离线'
    }
  }
}
</script>

<style lang="scss" scoped>
.imei {
  padding: 0 22px;
}

.card_container {
  margin-top: 24px;
  min-height: calc(100vh - 160px);

  .title {
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid #ebedf2;

    .color {
      width: 4px;
      height: 18px;
      background: linear-gradient(-75deg, #50e379, #50e398);
      box-shadow: 0px 3px 4px 0px rgba(80, 227, 146, 0.35);
      margin: 0 11px 0 28px;
    }

    span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #12203e;
    }
  }

  .selector {
    height: 82px;
    display: flex;
    align-items: center;
    padding: 0 22px;
  }

  .table_box {
    padding: 0 22px;

    .edit {
      cursor: pointer;
    }
  }
}
</style>
